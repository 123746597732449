import React from "react"
import Container from "../../components/container"
import CustomHeader from "../../components/customHeader"
import SideBarView from "../../components/SideBarView"
import Seo from "../../components/Seo"
import { Typography, Grid } from "@mui/material"
import { CodeBlockView } from "../../components/HelperComponents"

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const GolangTimestampConversions = () => {
  return (
    <Container>
      <CustomHeader title={`Golang Timestamp Conversions`}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography variant={`p`}>
            Golang provides a number of built-in functions for converting timestamps to different formats. In this
            article, we will discuss various types of timestamp conversions in Golang.
          </Typography>

          <br/><br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Timestamp to Time
          </Typography>
          <Typography variant={`p`}>
            To convert a Unix timestamp to a time.Time object, we can use the Unix() function of the time package.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Example:
          </Typography>

          {programmingRoutineView({
            language: `go`,
            code: `package main

import (
\t"fmt"
\t"time"
)

func main() {
\tunixTimestamp := int64(1615460256) // timestamp in seconds
\ttimeObj := time.Unix(unixTimestamp, 0)
\tfmt.Println(timeObj) // 2021-03-11 12:17:36 +0000 UTC
}`
          })}

          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Time to Timestamp
          </Typography>
          <Typography variant={`p`}>
            To convert a time.Time object to a Unix timestamp, we can use the Unix() function of the time package.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Example:
          </Typography>
          {programmingRoutineView({
            language: `go`,
            code: `package main

import (
\t"fmt"
\t"time"
)

func main() {
\tt := time.Now()
\tunixTimestamp := t.Unix()
\tfmt.Println(unixTimestamp) // 1615501798
}`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Timestamp to RFC3339 Format
          </Typography>
          <Typography variant={`p`}>
            RFC3339 is a standard format for representing dates and times in textual form. To convert a Unix timestamp
            to the RFC3339 format, we can use the Format() function of the time package.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Example:
          </Typography>
          {programmingRoutineView({
            language: `go`,
            code: `package main

import (
\t"fmt"
\t"time"
)

func main() {
\tunixTimestamp := int64(1615460256) // timestamp in seconds
\ttimeObj := time.Unix(unixTimestamp, 0)
\trfc3339Time := timeObj.Format(time.RFC3339)
\tfmt.Println(rfc3339Time) // 2021-03-11T12:17:36Z
}`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting RFC3339 Time to Timestamp
          </Typography>
          <Typography variant={`p`}>
            To convert a time in the RFC3339 format to a Unix timestamp, we can use the Parse() function of the time
            package.
          </Typography>
          {programmingRoutineView({
            language: `go`,
            code: `package main

import (
\t"fmt"
\t"time"
)

func main() {
\trfc3339Time := "2021-03-11T12:17:36Z"
\ttimeObj, _ := time.Parse(time.RFC3339, rfc3339Time)
\tunixTimestamp := timeObj.Unix()
\tfmt.Println(unixTimestamp) // 1615460256
}`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Timestamp to Custom Format
          </Typography>
          <Typography variant={`p`}>
            To convert a Unix timestamp to a custom format, we can use the Format() function of the time package and provide a format string.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Example:
          </Typography>
          {programmingRoutineView({
            language: `go`,
            code: `package main

import (
\t"fmt"
\t"time"
)

func main() {
\tunixTimestamp := int64(1615460256) // timestamp in seconds
\ttimeObj := time.Unix(unixTimestamp, 0)
\tcustomTime := timeObj.Format("02 Jan 2006 15:04:05 MST")
\tfmt.Println(customTime) // 11 Mar 2021 12:17:36 UTC
}
`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Custom Time to Timestamp
          </Typography>
          <Typography variant={`p`}>
            To convert a time in a custom format to a Unix timestamp, we can use the Parse() function of the time package and provide a format string.
          </Typography>
          <br/><br/>
          {programmingRoutineView({
            language: `go`,
            code: `package main

import (
\t"fmt"
\t"time"
)

func main() {
\tcustomTime := "11 Mar 2021 12:17:36 UTC"
\ttimeObj, _ := time.Parse("02 Jan 2006 15:04:05 MST", customTime)
\tunixTimestamp := timeObj.Unix()
\tfmt.Println(unixTimestamp) // 1615460256
}
`
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default GolangTimestampConversions

export const Head = () => {
  return (
    <Seo
      title="Timezone Magic: Timestamp Conversions Made Easy in GoLang"
      canonicalUrl={"https://www.epochconverter.io/blogs/python-timestamp-conversions"}
    />
  )
}
